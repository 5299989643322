@import "~bootstrap/scss/bootstrap";
@import "./fonts/stylesheet.css";

// @import url(./stylesheet.css);
body {
    #root{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
  &.menuActive {
    height: 100vh;
    overflow: hidden;
  }
}

.btn-wrapper {
    display: inline-block;
    position: relative;
    padding-bottom: 5px;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 5px;
        bottom: 5px;
        width: 100%;
        height: calc(100% - 5px);
        background: #fff;
        z-index: 0;
        border-radius: 6px;

    }
    .btn {
        position: relative;
        border: 0;
        z-index: 1;
        transform: translateY(-0.2em);
        transition: transform 0.1s ease;
        span {
            position: relative;
            z-index: 1;
        }
    }
    &:hover {
        .btn {
            transform: translateY(-0.33em);
            &:active {
                transform: translateY(5px);
            }
        }
    }
}

.text-right {
  text-align: right;
}
.main-body {
  background: url('/images/body-bg.jpg')no-repeat center;
  background-size: 100% 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    background: url('/images/body-bg-mob.png') no-repeat #000;
    background-size: 100% auto;
    background-position: bottom;
  }
}
.container.container-sm {
    max-width: 880px;
}

header {
    padding: 7px 0;
    .container {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}

.nabvar-brand {
    a {
        text-decoration: none;
        font-size: 16px;
        font-weight: 700;
        color: rgba(17, 14, 29, 0.6);
        max-width: 280px;
        display: flex;
        align-items: center;
        @media(min-width: 768px) {
            img {
                min-height: 50px;
            }
        }
        @media(max-width: 767px) {
            img {
                max-height: 50px;
            }
        }
    }
}

.btn-transparent {
    background: transparent;
    color: #262626;
}

.btn {
    font-size: 16px;
    padding: 10px 30px;
    font-family: 'Conthrax';
    border-radius: 6px;
    &.btn-purple {
        background: #970FA1;
        border-color: #970FA1;
        color: #fff;
        &:hover {
            background: #970FA1;
            border-color: #970FA1;
            color: #fff;
        }
    }
    &.btn-red {
        background: #D20A17;
        border-color: #D20A17;
        color: #fff;
        &:hover {
            background: #D20A17;
            border-color: #D20A17;
            color: #fff;
        }
    }
    
    &.btn-yellow {
        background: #F5C153;
        border-color: #F5C153;
        color: #fff;
        &:hover {
            background: #F5C153;
            border-color: #F5C153;
            color: #fff;
        }
    }
    &.btn-theme-outline {
        background: transparent;
        border-color: #E1C361;
        color: #fff;
        margin-top: 0px;
        position: relative;
        z-index: 20;
        @media screen and (max-width:768px) {
            margin-top: unset;
        }
        &:hover {
            background: #E1C361;
            border-color: #F5C153;
            color: #fff;
        }
    }
    &.btn-theme {
        background: linear-gradient(271.83deg, #BC9036 52.06%, #E4C664 96.13%);
        border: 2px solid #E1C361;
        color: #fff;
        
        &:hover,
        &:active,
        &:focus{
            background: #BC9036;
            border-color: #BC9036;
            color: #fff;
        }
        &:disabled {
            background: linear-gradient(271.83deg, rgba(188, 144, 54, 0.4) 52.06%, rgba(228, 198, 100, 0.4) 96.13%);;
            border-color: rgba(188, 144, 54, 0.4);
            color: #fff; 
        }
    }
    @media(max-width: 767px) {
        font-size: 16px;
        line-height: 19px;
        padding: 14px 16px;
    }
}

.buy-nft {
    padding: 40px 20px;
    flex: 1;
    .buy-section {
        background: linear-gradient(45deg, #000, #E4C664);
        padding: 2px 0;
        animation: gradientBorder 2s ease infinite;
        animation-direction: alternate;
        background-size: 600% 100%;
        max-width: 846px;
        border-radius: 20px;
        margin: 0 auto;
    }
    .buy-body {
        background: #000;
        padding: 40px 25px;
        border-radius: 20px;
        max-width: calc(100% - 4px);
        margin: 0 auto;
    }
    @media(max-width: 767px) {
        padding: 25px 20px 100px;
    }
}

.heading {
    margin: 0 0 50px;
    font-family: 'Conthrax';
    h2 {
        text-transform: uppercase;
        font-size: 36px;
        color: #fff;
        margin: 0;
    }
    h3 {
        font-size: 30px;
        color: #fff;
        margin: 0;
    }
    @media(max-width: 1199px) {
        h2 {
            font-size: 30px;
        }
        h3 {
            font-size: 24px;
        }
    }
    @media(max-width: 767px) {
        margin: 0 0 20px;
        h2 {
          font-size: 20px;
        }
    }
}

.btn-yellow {
    background: #FFC71F;
    border-color: #FFC71F;
    &:hover {
      background: #e3ad0b;
      border-color: #e3ad0b;
    }
}

.text-left {
    text-align: left;
}

.nft-img-sec {
    img, video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.buyDesc {
    .buyCalculator {
        max-width: 410px;
        margin-top: 20px;
        .buyCounter {
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            overflow: hidden;
            button {
                background: transparent;
                border: 0;
                padding: 8px 15px;
                color: #fff;
                font-size: 1.125rem;
            }
            input {
                height: 44px;
                background: transparent;
                flex: 0 0 calc(100% - 90px);
                width: calc(100% - 90px);
                text-align: center;
                border: 0;
                color: #fff;
                font-weight: 700;
                &:focus,
                &:focus-visible {
                    box-shadow: none;
                    border: 0;
                    outline: 0;
                }
            }
        }
        p {
            margin: 0 0 10px;
            font-weight: 700;
            font-size: 16px;
            color: #fff;
            &.dim {
                color: rgba(255, 255, 255, 0.6);
            }
        }
        .progress {
            background: rgba(255, 255, 255, 0.1);
            height: 0.875rem;
            border-radius: 40px;
            .progress-bar {
                background: #fff;
                border-radius: 40px;
            }
        }
        .btn-theme {
            padding: 12px 20px;
            font-size: 16px;
        }
    }
    ul.f-figures {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 0;
            p {
                margin: 0;
                font-size: 12px;
                font-weight: 700;
                color: #fff;
                &:first-child {
                    color: rgba(255, 255, 255, 0.6);
                }
            }
        }
    }
}

.btn-block {
    display: block;
    width: 100%;
}

 
 ul.social-media-list {
     list-style: none;
     padding: 0;
     margin: 0;
     display: flex;
     align-items: center;
     gap: 15px;
     a {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 100%;
        color: #262626;
        img {
           max-width: 28px;
        }
    }
 }

 footer {
    color: #fff;
    padding-bottom: 50px;
    ul.social-media-list {
        justify-content: center;
    }
    p {
        margin: 0;
    }
 }

 .default-layout {
    background: #000;
    text-align: center;
    color: #fff;
 }

 .full-menu {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    .mobile_menu{
        @media screen and (max-width:768px) {
            margin-top: -0.2rem;
        }
    }
    &.navbar-expand-md {
        .navbar-collapse {
            flex-grow: 0;
        }
        .navbar-nav {
            .dropdown-menu {
                background: #000;
                border: 1px solid #fff;
                border-radius: 10px;
                padding: 8px 0;
                .dropdown-item {
                    color: #fff;
                    font-family: "Titan One";
                    padding: 8px 20px;
                    &:hover {
                        background: #fff;
                        color: #000;
                    }
                }
            }
            .nav-item {
                &.show {
                    > .nav-link {
                        color: #fff;
                    }
                }
            }
            .nav-link {
                padding-left: 20px;
                padding-right: 20px;
           }
        }
    }
    .btn {
        padding: 12px 25px;
    }
    .nav-link {
        color: #fff;
        font-size: 16px;
    }
    .navbar-brand {
        width: 240px;
    }

    @media(max-width: 767px) {
        .btn {
            padding: 10px 12px;
            font-size: 12px;
        }
        .navbar-brand {
            max-width: 140px;
        }
        .navbar-collapse {
            height: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            transition: 0.25s all;
            &.show {
                height: calc(100vh - 87px);
            }
            .nav-link {
                font-size: 24px;
                padding: 15px 10px;
            }
        }
    }
 }

 .header-banner {
	position: relative;
	margin: 0 auto;
	.banner-bg {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
        z-index: 0;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
	}
	.banner-desc {
		min-height: 100vh;
		padding: 120px 0;
		display: flex;
		align-items: center;
		justify-content: flex-start;
        position: relative;
        z-index: 1;
        text-align: left;
	}
    .banner-wolf {
        @media(min-width: 767px) {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            top: 70px;
            height: calc(100vh - 70px);
        }
        .container-fluid,
        .row,
        .position-relative {
            height: 100%;
        }
        .position-relative {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            img {
                max-height: 100%;
            }
        }
        .wolf-image {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            display: inline-block;
            margin: 0 auto;
            max-height: 100%;
        }
    }
    &.inner-banner {
        .banner-bg {
            img {
                object-fit: unset;
            }
        }  
        .banner-desc {
            padding: 70px 0 210px;
            overflow: hidden;
        } 

        .coming-soon {
            position: absolute;
            left: -5px;
            right: 30px;
            bottom: 53px;            
            transform: rotate(-3deg);
            width: calc(100% + 100px);
            .coming-soon-stripe {
                position: relative;
                z-index: 1;
                padding: 30px 0;
                &.purple {
                    background: #970FA1;
                }
                &.red {
                    background: #D20A17;
                }
                &.yellow {
                    background: #F5C153;
                    p {
                        color: #000;
                        background: url('./images//star-purple.png') no-repeat;
                    }
                }
            }
            p {
                color: #fff;
                margin: 0;
                font-family: "Titan One";
                padding: 0 75px;
                background: url('./images//star.png') no-repeat;
                background-size: contain;
                background-position: left center;
            }
            &:after {
                content: "";
                position: absolute;
                right: 0;
                bottom: -70px;
                left: 0;
                width: 100%;
                background: #000;
                height: 102px;
                transform: rotate(2deg);  
                z-index: 0;             
            }
            @media(max-width: 767px) {
                p {
                    padding: 5px 40px 5px 50px;
                }
                .coming-soon-stripe {
                    padding: 9px 0;
                    font-size: 12px;
                }
                &:after {
                    height: 70px; 
                    bottom: -60px;
                }
            }
        }
        @media(max-width: 767px) {
            .banner-desc {
                padding: 150px 0 130px;
                min-height: max-content;
            }
        }
    }
    @media(max-width: 767px) {
        .banner-desc {
            min-height: max-content;
            padding: 120px 0 50px;
            .join_whitelist{
                width: 227px;
            }
        }
    }
}
.btn {
    &.btn-lg {
        font-size: 20px;
        padding: 24px 40px;
        @media(max-width: 767px){
            font-size: 18px;
            padding: 18px 40px
        }
    }
}
section {
	padding: 70px 0;
    @media(max-width: 767px){
        padding: 40px 0
    }
}

.missions {
	position: relative;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
        justify-content: center;
		flex-wrap: wrap;
		position: relative;
		z-index: 1;
		li {
			padding: 20px;
            width: 100%;
            position: relative;
			@media (min-width: 767px) {
                max-width: 50%;
			    flex: 0 0 50%;
            }
			@media (min-width: 991px) {
                max-width: 33.33%;
			    flex: 0 0 33.33%;
            }
            @media (max-width: 767px) {
                padding: 0;
            }
		}
	}
}
.single-mission {
    position: relative;
    z-index: 1;
	.misision-box {
		margin: 20px 0 0;
		text-transform: uppercase;
        background: url('./images/collection-bg.png') no-repeat;
        background-size: 100% 100%;
		.mission-body {
			color: #fff;
			padding: 30px 35px 0;
            margin: 0 auto;
            .mission-img {
                img {
                    max-width: 100%;
                }
            }
			.mission-desc {
				padding: 6px 0 10px;
				.heading {
					margin: 0 0 10px;
				}
			}
		}
		.mission-footer {
			padding: 40px;
            .btn-wrapper {
                width: 100%;
                max-width: 270px;
            }
			a {
				// color: #57CD77;
				// font-size: 20px;
                font-family: 'Conthrax';
				// margin: 20px 0 0;
				display: block;
				text-decoration: none;
			}
		}
	}
}

.partner-section {
    padding-top: 160px;
}


.twr-bg {
    background: url('./images/twr-bg.png') no-repeat;
    background-size: cover;
    padding-bottom: 150px;
    padding-top: 250px;
    margin-top: -160px;
}

.bg-wolf {
    background: url('./images/bg-wolf.png') no-repeat;
    background-size: cover;
    background-position: bottom;
    @media(max-width: 767px) {
        background: url('./images/bg-wolf-mob.png') no-repeat;
        background-size: cover;
        background-position: top;
    }
}

.bg-wolf-lens {
    background: url('./images/bg-wolf-lens.png') no-repeat;
    background-size: 100% auto;
    background-position: center;
    margin-top: -60px;
    @media(max-width: 767px) {
        background: url('./images/bg-wolf-lens-mob.png') no-repeat;
    }
}

.team-section {
    margin-top: -50px;
}

.whiteList-section {
    background: #000;
    padding: 40px 0;
    border-top: 1px solid #E4C664;
    border-bottom: 1px solid #E4C664;
    .coming-soon {
        p {
            margin: 0;
            font-family: "Conthrax";
            color: #E4C664;
            font-size: 14px;
            padding: 0 50px;
            background: url('./images//star.png') no-repeat;
            background-size: contain;
            background-position: left center;
        }
    }
    @media (max-width: 767px) {
        padding: 30px 0;
        .coming-soon {
            p { 
                padding: 0 30px 0 40px;
            }
        }
    }
}

.content {
    p {
        &.dim {
            opacity: 0.8;
        }
    }
}
.partner-list {
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		border: 1px solid rgba(255,255,255,.2);
		border-radius: 10px;
		background: #000;
		li {
			flex: 0 0 50%;
			max-width: 50%;
			height: 140px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-left: 1px solid rgba(255,255,255,.2);
			border-bottom: 1px solid rgba(255,255,255,.2);
            @media(max-width: 767px) {
                &:nth-child(2n + 1) {
                    border-left: 0;
                }
                &:nth-last-child(-n+2) {
                    border-bottom: 0;
                }
            }
			@media (min-width: 767px) {
                flex: 0 0 33.33%;
			    max-width: 33.33%;
                &:nth-child(3n + 1) {
                    border-left: 0;
                }
                &:nth-last-child(-n+3) {
                    border-bottom: 0;
                }
            }
			@media (min-width: 991px) {
                flex: 0 0 25%;
			    max-width: 25%;
                &:nth-child(4n + 1) {
                    border-left: 0;
                }
                &:nth-last-child(-n+4) {
                    border-bottom: 0;
                }
                &:nth-child(3n + 1) {
                    border-left: 1px solid rgba(255,255,255,.2);
                }
                &:nth-last-child(-n+3) {
                    border-bottom: 1px solid rgba(255,255,255,.2);
                }
            }

		}
	}

    .mobile-hidden {
        display: none;
      }
      
      @media (min-width: 767px) {
        .mobile-hidden {
          display: block; 
        }
      }

}



  

.whitepaper {
    background: #000;
    position: relative;
    overflow: hidden;
    z-index: 10000;
    border-radius: 10px;
    background: linear-gradient(45deg,#000, #E4C664);
    padding: 2px 0;
    animation: gradientBorder 2s ease infinite;
    animation-direction: alternate;
    background-size: 300% 100%;

    .whitepaper-section{
        padding: 70px 85px;
        background: #000;
        max-width: calc(100% - 4px);
        margin: 0 auto;
        border-radius: 10px;
    }

    @media(max-width: 767px) {
        .whitepaper-section{
            padding: 22px 20px;
        }
    }
}

.whatDoge {
    background: url('./images/what-bg.svg') no-repeat;
    background-size: contain;
    position: relative;
    padding: 130px 0 90px;
    // @media(max-width: 767px) {
    //     background: url('./images/what-bgMob.png') no-repeat;
    //     padding: 60px 0 90px;
    //     background-size: 100% auto;
    //     background-position: top;
    // }
}

.team-slider {
	.slick-slider {
        padding: 0 10px;
        .slick-slide {
            padding: 0 5px;
        }
    }
	.team-member {
		max-width: calc(100% - 15px);
		border-radius: 10px;
		color: #fff;
		text-transform: uppercase;
		padding: 20px 20px 0;
        background: url('./images/team-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
		.team-desc {
			padding: 20px 0 10px;
            min-height: 150px;
			.heading {
				margin: 0 0 10px;
			}
		}
        .team-footer {
            .btn {
              text-transform: uppercase;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              font-size: 12px;
              padding: 10px 30px;
              font-weight: 600;
              position: relative;
              z-index: 9;
            }
            .overlay-section {
              position: absolute;
              left: 0;
              right: 0;
              top: 100%;
              bottom: 0;
              padding: 25px 15px;
              background: rgba(0, 0, 0, 0.6);
              -webkit-backdrop-filter: blur(10px);
              backdrop-filter: blur(10px);
              border-radius: 10px;   
              transition: 0.25s all;
              max-height: 400px; /* Adjust this value as needed */
              overflow-y: auto;  /* Enable vertical scrolling */
            }
            .btn {
              &.active {
                + .overlay-section {
                  top: 0;
                }
              }
            }
          }
          
		.team-img {
			height: 230px;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				max-height: 100%;
			}
		}
	}
}



.roadmap {
	padding: 2px 0;
	text-align: left;
    border-radius: 10px;
    background: linear-gradient(45deg, #000, #E4C664);
    overflow: hidden;
    animation: gradientBorder 2s ease infinite;
    z-index: 20;
    animation-direction: alternate;
    background-size: 300% 100%;
    .card {
        padding: 40px 0;
        background: #000;
        margin: 0 auto;
        max-width: calc(100% - 4px);
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        z-index: 10000;
    }
	.card-body {
		max-width: 820px;
		margin: 0 auto;
	}
}

.shape {
    position: absolute;
    &.shape-top-right{
        top: 35px;
        left: calc(100% - 100px);
    }
    &.shape-bottom-left {
        right: calc(100% - 100px);
        bottom: 10px;
    }
    &.shape-bottom-left-60 {
        right: calc(100% - 60px);
        bottom: 10px;
    }
    &.shape-bottom-right-60 {
        left: calc(100% - 60px);
        bottom: 10px;
    }
    &.shape-12 {
        left: 0;
        bottom: 18%;
        transform: translate(-70%, 0);
    }
    &.shape-13 {
        right: 0;
        bottom: 12%;
        transform: translate(75%, 0);
    }
    &.shape-top35-left0 {
        right: 100%;
        top: 35%;
    }
    .giphy {
        max-width: 120px;
    }
    @media (max-width: 767px) {
        display: none;
    }
}

.timeline {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.timeline-item {
    position: relative;
    &.ml-auto {
        margin-left: auto;
        padding-left: 58px
    }
    &.mr-auto {
        padding-right: 58px
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            &:before {
                content: '';
                width: 12px; 
                height: 12px; 
                display: inline-block;
                margin-right: 10px;
                background: #57CD77;
                border-radius: 100%;
            }
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
    @media (max-width: 767px) { 
        .heading {
            font-size: 16px;
            margin: 0 0 10px;
        }
    }
}

.timeline-section {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 0 0 50px;
    flex-direction: column;
    .images-container {
        img {
            max-width: 100%;
        }
    }
    .road-progressbar {
        position: relative;
        padding-top: 50px;
        .timeline-progress {
            position: absolute;
            top: 0;
            width: 1px;
            height: 100%;
            border-radius: 2px;
            overflow: hidden;
            background-color: rgba(151, 15, 161, 0.5);;
            left: calc(50% - 1.5px);
        }
        .timeline-progress-bar {
            width: 100%;
            height: 0%;
            border-radius: 2px;
            background-color: #BC9036;
            transition: height .25s ease-in-out;
        }
        .timeline-item-indicator {
            position: relative;
            width: 44px;
            height: 44px;
            margin: 0 auto 40px;
            border-radius: 50%;
            transition: background 0.25s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            .timeline-item-indicator-inner {
                color: #fff;
                font-family: "Conthrax";
            }
        }
    }
    .timeline-item {
        text-align: center;
        max-width: 470px;
        .content {
            .heading {
                margin: 0 0 10px;
                h5 {
                    font-size: 20px;
                    line-height: 2;
                    margin: 0;
                }
            }
        }
    }
    &.visible {
        .road-progressbar {
            .timeline-progress {
                .timeline-progress-bar {
                    height: 100%;
                }
            }
        }
        .timeline-item-indicator {
            background: linear-gradient(271.83deg, #BC9036 52.06%, #E4C664 96.13%);
            transition-delay: .5s;
        }
    }
    
    &:first-child {
        .road-progressbar {
            padding-top: 0;
        }
    }
    @media (max-width: 767px) {  
        padding: 0;
    }
}

.faq-bg {
    background: #000;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
    background: linear-gradient(45deg,#000, #E4C664);
    background-size: 600% 100%;
    padding: 2px 0;
    position: relative;
    animation: gradientBorder 2s ease infinite;
    animation-direction: alternate;

    .faq-body {
        border-radius: 10px;
        overflow: hidden;
        padding: 50px 30px;
        margin: 0 auto;
        max-width: calc(100% - 4px);
        background: #000;
        
        @media(max-width: 767px) {
            padding: 40px 10px 10px;
        }
    }
}

.faq {
    .accordion {
        background: transparent;
        border: 0;
        border-radius: 0;
        position: relative;
        z-index: 20;

        .accordion-item {
            background: transparent;
            border: 0;
            margin: 0 0 10px;
            border-radius: 0;
            .accordion-collapse .accordion-body {
                border: 0;
                color: #fff;
            }
        }
        .accordion-header {
            border: 0;
            .accordion-button {
                background: transparent;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);;
                color: #fff;
                padding: 20px 0;
                padding-right: 35px;
                &:before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 13px;
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    background: #fff;
                }
                &:after {
                    z-index: 1;
                    position: absolute;
                    right: 5px;
                    top: 20px;
                }
            }
        }
    }
}

.pageFooter {
    background: url('./images/footer-bg.png') no-repeat;
    background-size: 100% auto;
    background-position: bottom;
    padding-bottom: 1050px;
    padding-top: 60px;
    @media(max-width: 1400px) {
        padding-bottom: 650px;
    }
    @media(max-width: 1199px) {
        padding-bottom: 550px;
    }
    @media(max-width: 767px) {
        background: url('./images/footer-bg-mob.png') no-repeat;
        padding-bottom: 420px;
        padding-top: 20px;
        background-size: 100% auto;
        background-position: bottom;
        ul.social-media-list {
            justify-content: center;
            margin: 0;
        }
    }
}


.burger-container {
    display: inline-block;
    height: 20px;
    width: 40px;
    padding: 0;
    cursor: pointer;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-right: 10px;
    .burger {
      height: 100%;
      position: relative;
      display: block;
      top: 50%;
      width: 100%;
      margin-top: -12px;
      .bar {
        width: 100%;
        height: 2px;
        display: block;
        position: relative;
        background: #57CD77;
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
        &.topBar {
          -webkit-transform: translateY(0px) rotate(0deg);
          transform: translateY(0px) rotate(0deg);
        }
        &.btmBar {
          bottom: -14px;
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        z-index: 1;
        background: #57CD77;
      }
    }
    &:not(.collapsed) {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      .burger {
        .bar {
          transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
          transition-delay: 0.2s;
          &.topBar {
            -webkit-transform: translateY(5px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
          }
          &.btmBar {
            top: 0;
            -webkit-transform: translateY(3px) rotate(-45deg);
            transform: translateY(3px) rotate(-45deg);
          }
        }
        &:before {
          opacity: 0;
        }
      }
    }
  }

@media (max-width: 767px) {
  footer {
    text-align: center;
  }
}

.phase-sec {
    position: relative;
    background: #000;
    padding: 60px 30px 15px;
    margin-top: 30px;
    .phase-bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 10px;
        overflow: hidden;
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 5px, transparent 5px) 0% 0%/10px 10px no-repeat,
            radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 5px, transparent 5px) 100% 0%/10px 10px no-repeat,
            radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 5px, transparent 5px) 0% 100%/10px 10px no-repeat,
            radial-gradient(circle at 0 0, #ffffff 0, #ffffff 5px, transparent 5px) 100% 100%/10px 10px no-repeat,
            linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 10px) calc(100% - 20px) no-repeat,
            linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 20px) calc(100% - 10px) no-repeat,
            linear-gradient(0deg, transparent 0%, #84cdbb 100%);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }  
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(3, 1, 15, 0.7);
        }      
    }
    .card-body {
        position: relative;
        z-index: 1;
        padding: 0;
    }
}

.phase-section {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
    .phase-img {
        flex: 0 0 100%;
        img {
            width: 100%;
        }
    }
    .phase-desc {
        flex: 0 0 100%;
        text-align: left;
        color: #66FAD5;
        margin-top: 15px;
        .heading {
            margin: 0;
            h4 {
                font-size: 20px;
            }
        }
        p {
            line-height: 1.75;
            font-size: 16px;
        }
    }
    &:nth-child(odd) {
        flex-direction: row-reverse;
    }
    @media(min-width: 767px) {
        padding: 40px 0;
        .phase-img {
            flex: 0 0 50%;
            padding: 0 30px;
        }
        .phase-desc {
            margin-top: 0;
            flex: 0 0 50%;
            padding: 0 30px;
            .heading {
                h4 {
                    font-size: 28px;
                }
            }
        }
    }
}

.extra-images {
    img {
        max-width: 100%;
    }
}

.nft-char {
    position: relative;
    padding: 60px 0;
    .nft-bg {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .nft-char-slider {
        width: calc(100% - 100px);
        margin: 0 auto;
        height: 456px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .slick-track {
            display: flex;
            align-items: flex-end;
        }
        .slick-slide {
            transition: 0.25s all; 
            padding: 0 20px;
            // transform: scale(0.5);
            // width: 228px;
            // height: 230px;
            div {
                width: 100%;
                img {
                    // height: 230px;
                    max-width: 400px;
                }
            }
            &.slick-current {
                // transform: scale(1);
                // width: 456px;
                div {
                    img {
                        // height: 456px
                    }
                }
            }
        }
    }
    @media(max-width: 767px) {
        padding: 40px 0 20px;
        margin: 0 -0.5rem;
        .nft-char-slider {
            width: calc(100% - 30px);
            height: auto; 
        }
    }
}



.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    font-size: 0;
    text-indent: -999px;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 100%;
    z-index: 9;
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 10px;
        height: 10px;
        border-left: 2px solid #000;
        border-bottom: 2px solid #000;
    }
    &.slick-prev {
        left: -10px;
        &:before {
            transform: translate(-36%, -50%) rotate(45deg);
        }
    }
    &.slick-next {
        right: -10px;
        &:before {
            transform: translate(-45%, -50%) rotate(-135deg);
        }
    }
}

@keyframes gradientBorder {
	0% {
		background-position: 0%;
	}
	100% {
		background-position: 100%;
	}
}
.connect_wallet{
    @media screen and (max-width:768px) {
        font-size: 10px;
        line-height: 12px;
    }
}