@font-face {
    font-family: 'Conthrax';
    src: url('ConthraxSb-Regular.eot');
    src: url('ConthraxSb-Regular.eot?#iefix') format('embedded-opentype'),
        url('ConthraxSb-Regular.woff2') format('woff2'),
        url('ConthraxSb-Regular.woff') format('woff'),
        url('ConthraxSb-Regular.ttf') format('truetype'),
        url('ConthraxSb-Regular.svg#ConthraxSb-Regular') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Inter';
    src: url('Inter-Regular.eot');
    src: url('Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('Inter-Regular.woff2') format('woff2'),
        url('Inter-Regular.woff') format('woff'),
        url('Inter-Regular.ttf') format('truetype'),
        url('Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



